import React, { useEffect, useState } from "react";
import DataExportRoute from "./DataExportRoute";
import radiology from "../../images/dasboardicon/Radiology.svg";
import DataExportTableNew from "./DataExportTableNew";
import dashboardStyles from "../dashboard/Dashboard.module.css";
import userIcon from "../../images/user-plus.svg";
import Button from "../custom/button/Button";
import file_icon from "../../images/file_icon.png";
import active from "../../images/tick.png";
import { useSelector } from "react-redux";
import useAxios from "../../axiosinstance";
import { useNavigate } from "react-router-dom";
import Message from "../custom/toster/Message";
export default function CompleteDataExport() {
  const axiosInstance = useAxios();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const navigate = useNavigate();
  const [exportData, setExportData] = useState([]);
  const [reff, setReff] = useState("");

  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [activeloading, setactiveLoading] = useState(true);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState("");
  const getDataExportData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/get_job_status/${login.ing_ak_id}?limit=${limit}&offset=0`
      );
      if (response.status === 200) {
        setExportData(response.data.data);
        setTotal(response.data.total);
        // const newData = response.data.data;
        // setExportData((prevColumns) => [...prevColumns, ...newData]);
        // if (newData.length < pageSize) {
        //   setHasMore(false);
        // }
        // setPage(page + 1)
        // const inProgressOrQueue = response.data.data.some(
        //   (item) => item.status === "inprogress" || item.status === "queue"
        // );

        // if (inProgressOrQueue) {
        //   setMessageType("success");
        //   setMessage("Export is being Processed");
        // }
        setactiveLoading(false);
      }
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } catch (err) {
      // setHasMore(false);
    }
  };

  useEffect(() => {
    getDataExportData();
  }, [reff]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/doctors/get_job_status/${login.ing_ak_id}?limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setExportData(exportData.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };
  const handleExportData = () => {
    navigate("/advance-data-export");
  };

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="start_new_case mb_100">
        <DataExportRoute title={"Advanced Data Export Complete"} />
        <div className="row page_header">
          <div className="col-sm-6 col-md-9">
            <h4 className="ph_title">Advanced Data Export Complete</h4>
          </div>
        </div>
        <div className="welcome-section-doc">
          <div className="white_img2 position-relative">
            <img src={file_icon} alt="logo" className="file-icon" />
            <img
              src={active}
              alt="logo"
              style={{
                height: "18px",
                width: "18px",
                right: "48%",
                top: "5px",
              }}
              className="icon_for_export position-absolute"
            />
          </div>
          <h4 className="f_700">Export Successful!</h4>
          <p className="f_18 f_400">
            your data is being processed and will be <br /> ready to download
            shortly
          </p>
        </div>
        <div className="row mt-4">
          <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="panel pt-3">
              <div className="ms-2">
                <div className="my_visit_screen org_tab ">
                  <div className="patients__list ">
                    <div className="row ">
                      <div className="col-sm-6 col-md-12">
                        <h4 className="ph_title ms-4">Advanced Data Exports</h4>
                        <p className="mb_25 ml_25">
                          All your recent and past data exports can be
                          downloaded here
                        </p>
                        <br></br>
                        <DataExportTableNew
                          data={exportData}
                          setMessageType={setMessageType}
                          setMessage={setMessage}
                          setactiveLoading={setactiveLoading}
                          activeloading={activeloading}
                          reff={"true"}
                          // hasMore={hasMore}
                          setReff={setReff}
                          updatePagination={fetchMoreData}
                          setOffset={setOffset}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`row ${dashboardStyles.card_individual_sec}`}
          style={{ paddingTop: "26px" }}
        >
          <div className={`col-sm-6`}>
            <div className={`whitebox text-center ${dashboardStyles.whitebox}`}>
              <div className="box-content">
                <img
                  src={file_icon}
                  alt="user-logo"
                  style={{ height: "30px" }}
                />
                <h4 className="f_700">Advanced Data Export</h4>
                <p className="f_30 f_400" style={{ fontSize: "14px" }}>
                  Export data using specific filters for data <br /> collection
                  purpose or research
                </p>
                <Button
                  border=""
                  color=""
                  minHeight="40px"
                  height=""
                  onClick={handleExportData}
                  radius="20px"
                  width="140px"
                  fontSize="14px"
                  buttonStyle="btn_outline f_600"
                  value="New Export "
                >
                  New Export
                </Button>
              </div>
            </div>
          </div>
          <div className={`col-sm-6`}>
            <div className={`whitebox text-center ${dashboardStyles.whitebox}`}>
              <div className="box-content">
                <img src={userIcon} alt="user-logo" />
                <h4 className="f_700">Audit Tool</h4>
                <p className="f_30 f_400" style={{ fontSize: "14px" }}>
                  Export a list of events, treatment notes, and <br /> PROMS for
                  a specific time period
                </p>
                <Button
                  border=""
                  color=""
                  minHeight="40px"
                  height=""
                  // onClick={handleAddPatient}
                  radius="20px"
                  width="140px"
                  fontSize="14px"
                  buttonStyle="btn_outline f_600"
                  value="Generate"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
