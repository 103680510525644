import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import useAxios from "../../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../custom/toster/Message";
import { noteRefresh } from "../../../redux/actions";
import deleteIcon from "../../../images/delete-red.svg";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
export default function MultiSelect({
  setMultidata,
  data,
  viewall,
  orgid,
  patienresourcevalue,
  rule_id,
  setShowModal,
  onDeleteResource,
  allPatients,
  allResources
}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [resourcetype, setResourceType] = useState([]);
  const [selectresource, setselectresource] = useState([]);
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [show, setShow] = useState(false);
  const [isApiPending, setIsApiPending] = useState(false);
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
    const[reff_id,setRef_id]=useState([])
  function getUniqueOptions(apiData, options, field) {
    const matchedValues = apiData.map((item) =>
      field === "org" ? item.label : item.value
    );
    const uniqueOptions = options.filter(
      (option) =>
        !matchedValues.includes(field === "org" ? option.label : option.value)
    );
    return uniqueOptions;
  }

  const handleResource = (e) => {
    setResourceType(e);
    if (viewall !== "true") {
      if (patienresourcevalue === "allResources") {
        setMultidata([...data.filter(item => !reff_id.includes(item.label)), ...e]);
      } else {
        setMultidata(e);
      }
    }
  };
  useEffect(() => {
    // if(viewall==="true"){
    if (viewall !== "true") {
      if (patienresourcevalue === "allResources") {
        setMultidata([...data.filter(item => !reff_id.includes(item.label)), ...resourcetype]);
      } else {
        setMultidata(resourcetype);
      }
    }
    if (viewall === "true") {
      if (data) {
        setResourceType(data);
      }
    }
  }, [data.length]);
  const [sendtyping, setsendtyping] = useState("");
  const handleInputChange = (inputValue) => {
    setLastTypingTime(new Date().getTime());
    setsendtyping(inputValue);
    // Add your logic to create a new option
    if (patienresourcevalue !== "allResources" && viewall !== "true") {
      if (inputValue < 3) {
        setselectresource([]);
      }
    }
  };
  useEffect(() => {
    if (orgid && patienresourcevalue === "allResources")
      axiosInstance.current
        .get(`/extapp/doctors/restype_of_org?org_id=${orgid}`)
        .then((response) => {
          const list = response.data.data
            .filter((item) => item.alias !== "")
            .map((item) => {
              return {
                label: item.alias,
                value: item.type,
              };
            });
          let uniqdata = getUniqueOptions(data, list);
          setselectresource(uniqdata);
        });
  }, [orgid, patienresourcevalue]);
 
  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const GetDiagnosis = (searchValue) => {
          try {
            // const searchValueArray = Array.isArray(searchValue) ? searchValue.map(item => item.value) : [searchValue.value];
            // const searchName = searchValueArray.join(',');
            axiosInstance.current
              .get(
                `/extapp/doctors/allHcpsOfOrgs?ak_id=${login.ing_ak_id}&keyword=${sendtyping}`
              )
              .then((response) => {
                const options = response.data.result;
                let uniqdata = getUniqueOptions(data, options, "org");
                let datas = uniqdata.map((item) => {
                  return {
                    label: item.label,
                    org_id: item.org_id,
                    doc_id: item.value,
                    value: item._id,
                  };
                });
                setselectresource(datas);
                // setDiagnosisidc(options);
              });
          } catch (error) {
            console.log(error);
          }
        };

        if (sendtyping.length >= 3 && patienresourcevalue !== "allResources") {
          GetDiagnosis();
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [sendtyping, patienresourcevalue]);

  const handleRequest = async (org_id, dc_id) => {
    setIsApiPending(true);
    // setShow(false);
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/sendPendingReq?rule_id=${rule_id}&ak_id_to=${dc_id}&accept=0&akou_id=${org_id}&ak_id=${login.ing_ak_id}`
      );

      if (response.status === 201) {
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setMessageType("success");
        setMessage("Request approved");
      } else {
        setMessageType("error");
        setMessage("Request not approved");
      }

      setTimeout(() => {
        setShowModal(false);
        setMessage(false);
        setIsApiPending(false);
      }, 3000);
    } catch (error) {
      setMessageType("error");
      setMessage("Request not approved");

      setTimeout(() => {
        setMessage(false);
        setIsApiPending(false);
      }, 3000);
    }
  };
  const [delete_id, setDelete_id] = useState({});
  const handleDelete = (id) => {
    setDelete_id(id);
    onDeleteResource(id);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };

  
   const[delete_label,setdelete_label]=useState('')
  const handleDeleteResource = () => {
    const updatedResources = data.filter(
      (item) => item.value !== delete_id
    );
    const updatedJson = {
      resource_types: updatedResources.map((item) => item.value),
      deleted_res_type:[Number(delete_id)]
    };
console.log(onDeleteResource,"onDeleteResource");

    axiosInstance.current.put(
      `/extapp/doctors/updateResourceRules?rule_id=${rule_id}&ak_id=${login.ing_ak_id}&org_id=${orgid}`,
      updatedJson,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        setDisplayConfirmationModalDelete(false)
        setRef_id([...reff_id,delete_label])
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setMessageType("success");
        setMessage("Deleted successful");
        setTimeout(() => {
          setMessage(false);
          // setShowModal(false);

        }, 3000);
      })
      .catch((error) => {
        console.log("Error updating resources:", error);
      });
  };
const handleDeleteHcp=async()=>{
  let json = JSON.stringify({
    akou_id: delete_id?.org_id,
    hcp_id: delete_id?.dc_id,
    resources:
      Array.isArray(allResources) && allResources.map((item) => item.value),
    allPatients:
      Array.isArray(allPatients) && allPatients.map((item) => item.value),
  });
  try {
    const response = await axiosInstance.current.put(
      `extapp/doctors/remove_doctor?rule_id=${rule_id}&ak_id=${login.ing_ak_id}`,
      json,{
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      setDisplayConfirmationModalDelete(false)
      setRef_id([...reff_id,delete_label])
      dispatch(noteRefresh(new Date().getMilliseconds()));
      setMessageType("success");
      setMessage("Deleted successful");
      setTimeout(() => {
        setMessage(false);
        // setShowModal(false);
       
      }, 3000);
    }
  } catch (err) {
    
  }
}
console.log(reff_id,"reff_idreff_id");

  const submitDelete = async () => {
    if(patienresourcevalue!=='allResources'){
      handleDeleteHcp()
      }else{
        handleDeleteResource()
      }
  };

  const hideConfirmationDelete = (org_id, dc_id,label) => {
    setdelete_label(label)
    if(patienresourcevalue!=='allResources'){
    setDelete_id({ org_id, dc_id });
    }else{
      setDelete_id(org_id)
    }
    setDisplayConfirmationModalDelete(true);
  };
  
  
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <CreatableSelect
        isCreatable={false}
        isMulti
        value={resourcetype}
        onChange={handleResource}
        options={selectresource}
        className="multi_select_drp11"
        onInputChange={handleInputChange}
        defaultMenuIsOpen={false}
        isValidNewOption={() => false}
        isDisabled={viewall === "true" ? true : false}
      />
      {patienresourcevalue !== "allResources" && viewall !== "true" ? (
        <span className="fst-italic fw-light" style={{ color: "#3F2783" }}>
          {"Search with minimum of three characters"}
        </span>
      ) : null}
      <hr></hr>
      <div>
        {viewall !== "true" && (
          <>
            <label>
              Already sharedddss{" "}
              {patienresourcevalue !== "allResources" ? "HCP(s)" : "Resource"}
            </label>
            <table className="multitable">
              <thead>
                <tr>
                  <th className="multitableth">
                    <strong>
                      {patienresourcevalue !== "allResources"
                        ? "HCP(s)"
                        : "Resource"}
                    </strong>
                  </th>

                  {patienresourcevalue !== "allResources" && (
                    <th className="multitableth">
                      <strong>Status</strong>
                    </th>
                  )}
                  {patienresourcevalue !== "allResources" && (
                    <th className="multitableth">
                      <strong>Action</strong>
                    </th>
                  )}
                  {patienresourcevalue == "allResources" && (
                    <th className="multitableth">
                      <strong>Action</strong>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.filter(item => !reff_id.includes(item.label)).map((item) => (
                  <tr key={item.id}>
                    <td className="multitabletd">
                      <div>
                        {Array.isArray(item.label)
                          ? item.label.join(", ")
                          : item.label}
                      </div>
                    </td>
                    {patienresourcevalue == "allResources" && (
                      <td className="multitabletd">
                        <div>
                          <img
                            className="ml_15"
                            src={deleteIcon}
                            alt="icon"
                            onClick={() => hideConfirmationDelete(item.value,'',item.label)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    )}
                    {patienresourcevalue !== "allResources" && (
                      <td className="multitabletd">
                        <div>
                          {item.accept === -1 && (
                            <span className="fw-bolder text-danger ">
                              Rejected
                            </span>
                          )}
                          {item.accept === 1 && (
                            <span className="fw-bolder text-success ">
                              Approved
                            </span>
                          )}
                          {item.accept === 0 && (
                            <span className="fw-bolder text-warning ">
                              Pending
                            </span>
                          )}
                        </div>
                      </td>
                    )}
                    {patienresourcevalue !== "allResources" && (
                      <td className="multitabletd" style={{ display: "flex",height: '58px'
                      }}>
                        {patienresourcevalue !== "allResources" &&
                        item.accept === -1 ? (
                          <div>
                            <button
                              className="btn_green"
                              style={{ width: "100px" }}
                              onClick={() =>
                                handleRequest(item.org_id, item.value)
                              }
                              disabled={isApiPending}
                            >
                              Re-request
                            </button>
                          </div>
                        ) : null}
                        <div style={{ marginRight: "2px" ,marginTop:"6px"}}>
                          <img
                            className="ml_15"
                            src={deleteIcon}
                            alt="icon"
                            onClick={() =>
                              hideConfirmationDelete(item.org_id, item.value,item.label)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={""}
        type="task_id"
        id={""}
      />
    </>
  );
}
