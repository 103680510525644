import React, { useEffect, useState, useRef } from "react";
import settingsStyle from "../Settings.module.css";
import InputField from "../../custom/inputfield/InputField";
import Dropdown from "../../custom/dropdown/Dropdown";
import CreatableSelect from "react-select/creatable";
import Button from "../../custom/button/Button";
import { ShareTable } from "./ShareTable";
import { Tabs, Tab } from "react-bootstrap";
import useAxios from "../../../axiosinstance";
import { Validators } from "../../../utilities/Validator";
import { useSelector } from "react-redux";
import Message from "../../custom/toster/Message";
export const Share = () => {
  const notreff = useSelector((state) => state.mainReducer.noteRef);
  const [rule, setRule] = useState("");
  const [org, setOrg] = useState("");
  const [org1, setOrg1] = useState("");
  const [resourcetype, setResourceType] = useState("");
  const [hcptype, setHcptype] = useState("");
  const [hcptype1, setHcptype1] = useState("");
  const [options, setoptions] = useState([]);
  const [options2, setoptions2] = useState([]);
  const [selectresource, setselectresource] = useState([]);
  const [selecthcp, setSelecthcp] = useState([]);
  const [selecthcp1, setSelecthcp1] = useState([]);
  const [activeTab, setActiveTab] = useState("allcases");
  const [org1Err, setorg1Err] = useState(false);
  const [resourcetypeErr, setresourcetypeErr] = useState(false);
  const [hcptypeErr, sethcptypeErr] = useState(false);
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [Manufacturer, setManufacturer] = useState("");
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const org1Ref = useRef();
  const ruleRef = useRef();
  const orgRef = useRef();
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const handleRule = (e) => {
    setRule(e);
  };
  const handleOrg = (e) => {
    setOrg(e);
    setHcptype("")
  };
  const handleManufacturer=(e)=>{
    setManufacturer(e)
  }
  const handleOrg1 = (e) => {
    setOrg1(e);
    setHcptype1("")
  };
  const handleResource = (e) => {
    setResourceType(e);
  };
  // const handleInputChange = (e) => {
  //   setResourceType(e);
  // };
  const handleHCP = (e) => {
    setHcptype(e);
    setSelecthcp([]);
  };
  const handleHCP1 = (e) => {
    setHcptype1(e);
  };
  const [reff, setReff] = useState("");
  const handleSave = async () => {
    if (!rule) {
      ruleRef.current.errUpdate();
    }
    if (!org) {
      orgRef.current.errUpdate();
    }
    if (resourcetype.length === 0) {
      setresourcetypeErr(true);
    }
    if (hcptype.length === 0) {
      sethcptypeErr(true);
    } else if (
      rule &&
      org &&
      resourcetype.length !== 0 &&
      hcptype.length !== 0
    ) {
      let hcptypedata = hcptype.map((item) => {
        return {
          org_id: item.org_id,
          hcp_id: item.doc_id,
        };
      });
      let restype = resourcetype.map((item) => item.value);
      let json = JSON.stringify({
        name: rule,
        akou_id_from: org,
        ak_id_from: login.ing_ak_id,
        akou_id_to: hcptypedata,
        resource_types: restype,
        patients: [],
      });
      try {
        const response = await axiosInstance.current.post(
          `/extapp/doctors/createRules`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          setReff(new Date().getMilliseconds());
          setMessageType("success");
          setMessage("Data Sharing save successful");
          setRule("");
          setOrg("");
          setHcptype("");
          setResourceType([]);
        }
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleSave1 = async () => {
    if (!org1) {
      org1Ref.current.errUpdate();
    }
    if (hcptype1.length === 0) {
      return setorg1Err(true);
    } else if (org1 && hcptype1.length !== 0) {
      let data = hcptype1?.map((item) => item.value);
      try {
        const response = await axiosInstance.current.post(
          `extapp/doctors/sharing_within_org?ak_dc=${login.ing_ak_id
          }&akou=${org1}&shared_ak_dc=${String(data)}`
        );
        if (response) {
          setReff(new Date().getMilliseconds());
          setMessageType("success");
          setMessage("Sharing within organization successful");
          setOrg1("");
          setHcptype1([]);
          setHcptype("");
        }
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleTab = (e) => {
    setActiveTab(e);
  };
  const axiosInstance = useAxios();
  useEffect(() => {
    axiosInstance.current
      .get(
        `extapp/doctors/getAllDocSharingOrganizations?doctor_id=${login.ing_ak_id}`
      )
      .then((response) => {
        setoptions(response.data.data);
      });
  }, []);
  useEffect(() => {
    axiosInstance.current
      .get(
        `extapp/doctors/getAllUserOrganizations?doctor_id=${login.ing_ak_id}`
      )
      .then((response) => {
        setoptions2(response.data.data);
      });
  }, []);
  // useEffect(() => {
  //   if (org1)
  //     axiosInstance.current
  //       .get(
  //         `extapp/doctors/hcp_lists_org_based?akou=${org1}&ak_dc=${login.ing_ak_id}&keyword=`
  //       )
      
  //       .then((response) => {
     
  //         setSelecthcp1(response.data.doctor_names);
  //         if(response.data.data.status===false){
  //           alert(1)
  //           setSelecthcp1("")
  //         }
  //       });
        
  // }, [org1]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/hcp_lists_org_based?akou=${org1}&ak_dc=${login.ing_ak_id}&keyword=`
        );
        
        setSelecthcp1(response.data.doctor_names);
      } catch (error) {
       
        setSelecthcp1([])
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [org1]);
  
  useEffect(() => {
    if (org)
      axiosInstance.current
        .get(`/extapp/doctors/restype_of_org?org_id=${org}`)
        .then((response) => {
          const list = response.data.data
            .filter((item) => item.alias !== "")
            .map((item) => {
              return {
                label: item.alias,
                value: item.type,
              };
            });
          setselectresource(list);
        });
  }, [org]);
  const [sendtyping, setsendtyping] = useState("");
  const handleInputChange = (inputValue) => {
    setLastTypingTime(new Date().getTime())
    setsendtyping(inputValue);
    setSelecthcp([]);
    // Add your logic to create a new option
  };
  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const GetDiagnosis = (searchValue) => {
          try {
            // const searchValueArray = Array.isArray(searchValue) ? searchValue.map(item => item.value) : [searchValue.value];
            // const searchName = searchValueArray.join(',');
            axiosInstance.current
              .get(
                `/extapp/doctors/allHcpsOfOrgs?ak_id=${login.ing_ak_id}&keyword=${sendtyping}&akou_id=${org}`
              )
              .then((response) => {
                const options = response.data.result;
                let datas=options.map((item)=>{
                  return{
                  label: item.label,
                  org_id:item.org_id,
                  doc_id:item.value,
                  value:item._id
                  }
                })
                setSelecthcp(datas);
                // setDiagnosisidc(options);
              });
          } catch (error) {
            console.log(error);
          }
        };
        if (sendtyping.length >= 3) {
          GetDiagnosis();
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [sendtyping, lastTypingTime]);
  const [orgShare, setOrgShare] = useState([]);

  console.log(org1,"org1org1",org);
  
  useEffect(() => {
    try {
      axiosInstance.current
        .get(`/extapp/doctors/getWithInOrgSharedData?ak_dc=${login.ing_ak_id}`)
        .then((response) => {
          console.log(response.data.shared_to);
          setOrgShare(response.data?.sharedData);
        });
    } catch (error) {
      console.log(error);
    }
  }, [reff]);
  const [Sharingdata, setSharingdata] = useState([]);
  useEffect(() => {
    try {
      axiosInstance.current
        .get(
          `/extapp/doctors/getallrules?ak_id=${login.ing_ak_id}&limit=100&offset=0`
        )
        .then((response) => {
          setSharingdata(response.data.data);
          // setOrgShare(response.data.sharedData[0].shared_to);
        });
    } catch (error) {
      console.log(error);
    }
  }, [reff, notreff]);
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div>
        <h4 class={`${settingsStyle.content_main_title}`}> Data Sharing </h4>
      </div>
      <div className="my_visit_screen org_tab mb_100">
        <Tabs
          id="case-list-tabs"
          activeKey={activeTab}
          onSelect={handleTab}
          className="border-0"
        >
           <Tab eventKey="allcases" title={"Intra-Organization Sharing"}>
            <div className="p-3">
              <div className="row">
                <div className="col-md-12">
                  <label>
                    <i className="text-danger">
                      Note :- Once shared within the organization, you won’t be
                      able to remove it in the future.
                    </i>
                  </label>
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={options2}
                    label="Sharing Organization*"
                    value={org1}
                    onChange={handleOrg1}
                    styleClass="br_10 mxw_100 mb_20"
                    placeholder="Select"
                    ref={org1Ref}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 ">
                  <label className="form-label">Recipient Organization*</label>
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={hcptype1}
                    onChange={handleHCP1}
                    options={selecthcp1}
                    className="multi_select_drp1"
                    defaultMenuIsOpen={false}
                    isValidNewOption={() => false}
                  />
                 
                  {org1Err && (
                    <>
                      {hcptype1.length === 0 && (
                        <span className="text-danger">
                          This field is required{" "}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="col-sm-7 col-md-7"
                  style={{ marginTop: "2rem", textAlign: "end" }}
                >
                  <Button
                    onClick={() => handleSave1()}
                    value="Share"
                    buttonStyle="btn_fill mr_20"
                  />
                </div>
              </div>
              <ShareTable data={orgShare} id="Share" />
            </div>
          </Tab>
          <Tab eventKey="submitted" title={"Inter-Organization Sharing"} >
            <div className="p-3">
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label="Rule*"
                    value={rule}
                    type="text"
                    placeholder="Enter Rule"
                    onChange={handleRule}
                    styleClass="br_10 mw-100"
                    ref={ruleRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 ">
                  <Dropdown
                    data={options}
                    label="Sharing Organization*"
                    value={org}
                    onChange={handleOrg}
                    styleClass="br_10 mxw_100 mb_20"
                    placeholder="Select"
                    ref={orgRef}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                  />
                </div>
                <div className="col-sm-5 col-md-5 ">
                  <label className="form-label">
                    Shared resource type*
                  </label>
                  <CreatableSelect
                    isCreatable
                    isMulti
                    value={resourcetype}
                    onChange={handleResource}
                    // onInputChange={handleInputChanges}
                    options={selectresource}
                    className="multi_select_drp1"
                    defaultMenuIsOpen={false}
                    isValidNewOption={() => false}
                  />
                  {resourcetypeErr && (
                    <>
                      {resourcetype.length === 0 && (
                        <span className="text-danger">
                          This field is required{" "}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 ">
                  <label className="form-label">Recipient Organization*
                  
                  </label>
      
                  {/* <label className="form-label">(Belongs to  other organization) */}
                  
                  {/* </label> */}
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={hcptype}
                    onChange={handleHCP}
                    options={selecthcp}
                    className="multi_select_drp1"
                    onInputChange={handleInputChange}
                    defaultMenuIsOpen={false}
                    isValidNewOption={() => false}
                  />
                  <span
                    className="fst-italic fw-light"
                    style={{ color: "#3F2783" }}
                  >
                    {"Search with minimum of three characters"}
                  </span>
                  <br></br>
                  {hcptypeErr && (
                    <>
                      {hcptype.length === 0 && (
                        <span className="text-danger">
                          This field is required{" "}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="col-sm-7 col-md-7 d-flex"
                  style={{ marginTop: "2rem" }}
                >
                  <Button
                    onClick={() => handleSave()}
                    value="Save"
                    buttonStyle="btn_fill mr_20"
                  /> 
                  {/* <InputField
                  styleClass="br_10 mxw_100"
                  formGroupClass='w-378'
                  label="Implant Manufacturer"
                  value={Manufacturer}
                  type="text"
                  placeholder="Enter Implant Manufacturer"
                  onChange={handleManufacturer}
                  />
                  <Button
                    onClick={() => handleSave()}
                    value="Save"
                    buttonStyle="btn_fill mr_20"
                    marginLeft="9px"
                    marginTop="2rem"
                  /> */}
                </div>
              </div>
              <hr />
              <ShareTable data={Sharingdata} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};