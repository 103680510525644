import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import MultiSelect from "./MultiSelect";
import { ModalBody } from "react-bootstrap";
import Button from "../../custom/button/Button";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";
import { noteRefresh } from '../../../redux/actions';
import { useSelector,useDispatch } from "react-redux";
export default function HcpPatientModel({
  modelOpen,
  setShowModal,
  patienresource,
  patienresourcevalue,
  orgid,
  rule_id,
  setOrgid,
  allPatients,
  allResources
}) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [Multidata, setMultidata] = useState([]);
  const handleClose = () => {
    setShowModal(false);
    setOrgid("")
  };


  console.log(allResources,"allResourcesallResourcesallResources");
  
  const json = {
    akou_id_to: Multidata.map((item) => {
      return {
        org_id: item.org_id,
        hcp_id: item.doc_id,
      };
    }),
  };
 

  const handleSave = async () => {
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/updateDoctorRules?rule_id=${rule_id}&ak_id=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setMessageType("success");
        setMessage("Update rule successful");
      }
      setTimeout(() => {
        setMessage(false);
        setShowModal(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  const json1 ={
    resource_types: Multidata.map((item) => item.value),
  }

  const handleSave1 = async () => {
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/updateResourceRules?rule_id=${rule_id}&ak_id=${login.ing_ak_id}`,
        json1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setMessageType("success");
        setMessage("Update rule successful");
      }
      setTimeout(() => {
        setMessage(false);
        setShowModal(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };



 
  
  return (
    <div>
      {message && <Message message={message} type={messageType} />}
      <Modal
        show={modelOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-lg patient_notes_popup modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {patienresourcevalue === "allResources" ? "Resources" : "HCP(s)"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <label>
            {patienresourcevalue !== "allResources"
              ? "HCP(s) to share with"
              : "Resource type to share with"}
          </label>
          <MultiSelect
            data={patienresource}
            setMultidata={setMultidata}
            setShowModal={setShowModal}
            orgid={orgid}
            patienresourcevalue={patienresourcevalue}
            rule_id={rule_id}
            allPatients={allPatients}
            allResources={allResources}
          />
        </Modal.Body>
        <Modal.Footer>
          {patienresourcevalue !== "allResources" ? (
            <Button
              onClick={() => handleSave()}
              value="Share"
              buttonStyle="btn_fill mr_20"
            />
          ) : (
            <Button
              onClick={() => handleSave1()}
              value="Share"
              buttonStyle="btn_fill mr_20"
            />
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
